import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "class-competition",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#class-competition",
        "aria-label": "class competition permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Class Competition`}</h1>
    <h2 {...{
      "id": "rubric",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#rubric",
        "aria-label": "rubric permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Rubric`}</h2>
    <p>{`To receive a grade, you must create a pull request in the course blog repository with a final (updated) version of your class competition summary. `}<strong parentName="p">{`Note that you should be updating the same file used for your draft.`}</strong>{` The final version of your class competition summary will be assessed across 8 categories.  Each category (described below) will be graded on either a Superior/Pass/Fail or Pass/Fail basis.  Assignment letter grades are calculated in the following manner:`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
      <tbody>
        <tr>
            <td><strong>Grade</strong></td>
            <td><strong>Criteria</strong></td>
        </tr>
        <tr>
            <td>
              <p>A</p>
            </td>
            <td>
              <p>No <strong>Fail</strong> and at least 2 <strong>Superior</strong></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>B</p>
            </td>
            <td>
              <p>All <strong>Pass</strong></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>C</p>
            </td>
            <td>
              <p>1 <strong>Failure</strong></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>D</p>
            </td>
            <td>
              <p>2 <strong>Failure</strong></p>
            </td>
        </tr>
        <tr>
            <td>
              <p>F</p>
            </td>
            <td>
              <p>3+ <strong>Failure</strong></p>
            </td>
        </tr>
    </tbody>
    </HTMLTable>
    <h3 {...{
      "id": "task-summary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#task-summary",
        "aria-label": "task summary permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Task summary`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior`}</strong>{`: Pass criteria + one or more of the following: `}
        <ul parentName="li">
          <li parentName="ul">{`a)discussion of related tasks`}</li>
          <li parentName="ul">{`b) challenges of task (with examples)`}</li>
          <li parentName="ul">{`c) discussion of SotA approaches`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Pass`}</strong>{`: All of the following: `}
        <ul parentName="li">
          <li parentName="ul">{`a) description of task (label space, dataset size, design, etc.)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Fail`}</strong>{`: Task Description is inadequate or absent`}</li>
    </ul>
    <h3 {...{
      "id": "exploratory-data-analysis",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#exploratory-data-analysis",
        "aria-label": "exploratory data analysis permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exploratory data analysis`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior`}</strong>{`: Pass criteria + additional analysis (ex. measures of diversity)  `}</li>
      <li parentName="ul"><strong parentName="li">{`Pass`}</strong>{`: All of the following: `}
        <ul parentName="li">
          <li parentName="ul">{`a) observations supported with example data points`}</li>
          <li parentName="ul">{`b) dataset size (per class)  `}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Fail`}</strong>{`: EDA is inadequate or absent`}</li>
    </ul>
    <h3 {...{
      "id": "approach",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#approach",
        "aria-label": "approach permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Approach`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior`}</strong>{`: Pass criteria + approach highlights some novelty/creativity `}</li>
      <li parentName="ul"><strong parentName="li">{`Pass`}</strong>{`: Description of Approach and motivation for use.`}</li>
      <li parentName="ul"><strong parentName="li">{`Fail`}</strong>{`: Description of Approach is inadequate or absent`}</li>
    </ul>
    <h3 {...{
      "id": "results",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#results",
        "aria-label": "results permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Results`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior`}</strong>{`: Pass criteria + measure of robustness of approach (ex. stratified k-fold cross validation, etc.) and/or statistical analysis `}</li>
      <li parentName="ul"><strong parentName="li">{`Pass`}</strong>{`: Quantitative results on leaderboard noting delta with baseline`}</li>
      <li parentName="ul"><strong parentName="li">{`Fail`}</strong>{`: Reporting of Results is inadequate or absent`}</li>
    </ul>
    <h3 {...{
      "id": "error-analysis",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#error-analysis",
        "aria-label": "error analysis permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Error analysis`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior`}</strong>{`: Detailed error analysis on held-out data (characterization of error) with examples`}</li>
      <li parentName="ul"><strong parentName="li">{`Pass`}</strong>{`: Some error analysis on held-out data`}</li>
      <li parentName="ul"><strong parentName="li">{`Fail`}</strong>{`: Error analysis is inadequate or absent`}</li>
    </ul>
    <h3 {...{
      "id": "reproducibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#reproducibility",
        "aria-label": "reproducibility permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reproducibility`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior`}</strong>{`: Containerized build with clear step-by-step instructions for reproducing leaderboard numbers`}</li>
      <li parentName="ul"><strong parentName="li">{`Pass`}</strong>{`: clear step-by-step instructions for installing dependencies and reproducing leaderboard numbers`}</li>
      <li parentName="ul"><strong parentName="li">{`Fail`}</strong>{`: Unable to reproduce results (ex. lack of instructions, missing components, etc.)`}</li>
    </ul>
    <h3 {...{
      "id": "leaderboard-result",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#leaderboard-result",
        "aria-label": "leaderboard result permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Leaderboard result`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Superior`}</strong>{`: Pass criteria + leaderboard submission exceeds the baseline  `}</li>
      <li parentName="ul"><strong parentName="li">{`Pass`}</strong>{`: All of the following: `}
        <ul parentName="li">
          <li parentName="ul">{`a) post specifies `}<strong parentName="li">{`name of submission on leaderboard`}</strong></li>
          <li parentName="ul">{`b) post includes `}<strong parentName="li">{`score of leaderboard submission`}</strong></li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Fail`}</strong>{`: Any of the following: `}
        <ul parentName="li">
          <li parentName="ul">{`a) post does not specify name of submission on leaderboard`}</li>
          <li parentName="ul">{`b) post does not include score of leaderboard submission`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "link-to-code-repo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#link-to-code-repo",
        "aria-label": "link to code repo permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Link to code repo`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Pass`}</strong>{`: All of the following: `}
        <ul parentName="li">
          <li parentName="ul">{`a) post includes a link to the code repository `}</li>
          <li parentName="ul">{`b) the code repository is not empty`}</li>
          <li parentName="ul">{`c) the repository uses the required assignment template (i.e., the repository is owned by the appropriate GitHub organization)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Fail`}</strong>{`: Any of the following: `}
        <ul parentName="li">
          <li parentName="ul">{`a) post does not include a link to the code repository`}</li>
          <li parentName="ul">{`b) the code repository is empty`}</li>
          <li parentName="ul">{`c) the repository does not use the required assignment template`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      